/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TaxFormDialog from 'components/global/TaxFormDialog';
import GlobalTitle from 'components/global/Title';
import Button from 'components/global/mui4/Button';
import ProviderTypeChip from 'components/global/mui4/ProviderTypeChip';
import DataLoadingSpinner from 'components/global/DataLoadingSpinner';
import axios from 'axios';
import {
  fetchAppointment,
  cancelAppointment,
  completeAppointment,
  fetchAdminAppointment,
  updateAdminAppointmentStatus,
  cancelAdminAppointment,
} from 'actions/session_status';
import { openGlobalSnackbar } from 'actions/global_snackbar';
import { headers } from '../../../actions/defaults';
import { COUNTRY, API_URL } from '../../../environment';

const useStyles = makeStyles({
  dialogBackground: {
    backgroundColor: '#f9f9f9',
    borderRadius: 12,
    padding: 24,
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  sessionInfoBox: {
    margin: '16px 30px',
    padding: '10px 20px',
    minWidth: 420,
    borderRadius: 12,
    border: '1px solid #7EA2F4',
  },
  sessionInfoTitle: {
    marginBottom: '0.5em',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: '0.2px',
    color: '#3c75c7',
  },
  sessionInfoDesc: {
    marginBottom: '1.5em',
    fontSize: '16px',
    fontWeight: '600',
    whiteSpace: 'pre-line',
    lineHeight: '18px',
  },
  radioBox: {
    margin: 30,
    minWidth: 420,
  },
  reasonBox: {
    marginTop: 10,
    marginLeft: 11,
    paddingLeft: 22,
    borderLeft: '1px solid #EBECF2',
  },
  title: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0,
    color: '#242546',
    margin: 0,
  },
  subTitle: {
    color: '#3C75C7',
    fontFamily: 'Barlow',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '0.2px',
    marginBottom: 10,
  },
  radioDescription: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.2,
    color: '#242546',
    marginLeft: 33,
    marginTop: '-5px',
    marginBottom: 10,
    minHeight: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#6080B9',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  errorMessageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 40,
  },
  errorMessage: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.2,
    color: '#BE0F00',
    margin: 0,
  },
});

const CustomFormControlLabel = withStyles({
  root: {
    margin: 0,
  },
  label: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0,
    color: '#242546',
  },
})(FormControlLabel);

const CustomRadio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
})(Radio);

function SessionCompletion(props) {
  const classes = useStyles();
  const {
    open,
    intl,
    appointmentId,
    loading,
    appointment,
    submitting,
    isAdmin,
    isForUpdate,
    appointmentStatus,
    cancellation,
  } = props;
  const [sessionStatus, setSessionStatus] = useState(appointmentStatus);
  const [isConsultation, setIsConsultation] = useState(false);
  const [hasPayout, setHasPayout] = useState(false);
  const [reason, setReason] = useState(cancellation?.reason?.key);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [taxDialog, setTaxDialog] = useState(false);

  const helpTextShown = !isAdmin;

  useEffect(() => {
    const fetch = isAdmin
      ? props.fetchAdminAppointment
      : props.fetchAppointment;
    fetch(appointmentId);
  }, []);

  useEffect(() => {
    setIsConsultation(appointment?.counselling_type === 'consultation');
    setHasPayout(appointment?.payout_yn);
  }, [appointment]);

  const handleChangeSessionStatus = event => {
    if (event.target.value === 'complete') {
      setTaxDialog(true);
    }
    setSessionStatus(event.target.value);

    if (event.target.value) {
      setShowError(false);
    }
  };

  const handleChangeReason = event => {
    if (
      event.target.value === 'client_absence' ||
      event.target.value === 'client_late_cancellation'
    ) {
      setTaxDialog(true);
    }
    setReason(event.target.value);

    if (event.target.value) {
      setShowError(false);
    }
  };

  const completedCallback = (res, error) => {
    if (!error) {
      props.openGlobalSnackbar(
        intl.formatMessage({
          defaultMessage: 'Session successfully completed!',
        }),
      );
      props.handleClose();
      props.handlePostChange();
    } else {
      setErrorMessage(_.get(error, 'data.error', 'Something went wrong.'));
      setShowError(true);
    }
  };

  const handleSubmit = () => {
    if (!sessionStatus) {
      setErrorMessage(
        intl.formatMessage({
          defaultMessage: '* Please select a status to complete the session.',
        }),
      );
      setShowError(true);
    }

    if (sessionStatus === 'canceled' && !reason) {
      setErrorMessage(
        intl.formatMessage({
          defaultMessage: '* Select a reason to cancel the appointment.',
        }),
      );
      setShowError(true);
    }

    if (sessionStatus === 'complete') {
      if (isAdmin) {
        props.updateAdminAppointmentStatus(
          appointment.id,
          'completed',
          completedCallback,
        );
      } else {
        props.completeAppointment(appointment.id, completedCallback);
      }
    }

    if (isAdmin && sessionStatus === 'pending') {
      props.updateAdminAppointmentStatus(
        appointment.id,
        'pending',
        completedCallback,
      );
    }

    if (sessionStatus === 'canceled' && reason) {
      const userId = [
        'client_cancellation',
        'client_late_cancellation',
      ].includes(reason)
        ? appointment.client.id
        : appointment.counsellor.id;

      let reasonCode = null;
      const cancel = isAdmin
        ? props.cancelAdminAppointment
        : props.cancelAppointment;

      switch (reason) {
        case 'client_absence':
          reasonCode = 'client_absence';
          break;
        case 'technical_difficulties':
          reasonCode = 'technical_difficulties';
          break;
        default:
          reasonCode = null;
      }

      if (appointment.cancellation) {
        const values = {
          id: appointment.cancellation.id,
          cancellation: {
            user_id: userId,
            reason: reasonCode,
          },
        };
        axios
          .put(
            `${API_URL}/admin/appointments/${appointmentId}/cancellations/${appointment.cancellation.id}`,
            values,
            headers(),
          )
          .then(() => {
            props.openGlobalSnackbar(
              intl.formatMessage({
                defaultMessage: 'Successfully modified appointment',
              }),
            );
            props.handleClose();
            props.handlePostChange();
          });
      } else {
        cancel(appointment.id, userId, reasonCode, (_res, error) => {
          if (!error) {
            props.openGlobalSnackbar(
              intl.formatMessage({
                defaultMessage: 'Successfully cancelled appointment',
              }),
            );
            props.handleClose();
            props.handlePostChange();
          } else {
            setErrorMessage(
              _.get(error, 'data.error', 'Something went wrong.'),
            );
            setShowError(true);
          }
        });
      }
    }
  };

  const modalityIntl = modality => {
    switch (modality.toLowerCase()) {
      case 'video':
        return intl.formatMessage({ defaultMessage: 'Video' });
      case 'voice':
        return intl.formatMessage({ defaultMessage: 'Voice' });
      case 'in person':
        return intl.formatMessage({ defaultMessage: 'In Person' });
      default:
    }

    return modality;
  };

  const renderSessionInfo = () => {
    const modality = _.get(appointment, 'modality', '');
    const providerType = _.get(appointment, 'provider_type', 'individual');
    const startDate = appointment
      ? moment(appointment.start_date).format('LT')
      : moment().format('LT');
    const endDate = appointment
      ? moment(appointment.end_date).format('LT')
      : moment().format('LT');
    const length = appointment
      ? moment(appointment.end_date).diff(
          moment(appointment.start_date),
          'minutes',
        )
      : 0;

    let clientName = '';

    if (providerType === 'couples') {
      clientName = `${_.get(
        appointment,
        'client.preferred_name_with_last_name',
        '',
      )} and ${_.get(
        appointment,
        'couples_appointment_detail.guest.preferred_name_with_last_name',
        '',
      )}`;
    } else {
      clientName = _.get(
        appointment,
        'client.preferred_name_with_last_name',
        '',
      );
    }

    return (
      <div>
        <p className={classes.sessionInfoTitle}>
          {intl.formatMessage({ defaultMessage: 'Client' })}
        </p>
        <p className={classes.sessionInfoDesc}>{clientName}</p>
        <p className={classes.sessionInfoTitle}>
          {intl.formatMessage({ defaultMessage: 'Type of Appointment' })}
        </p>
        <p className={classes.sessionInfoDesc}>
          {modalityIntl(modality)} <ProviderTypeChip type={providerType} />
        </p>
        <p className={classes.sessionInfoTitle}>
          {intl.formatMessage({ defaultMessage: 'Time' })}
        </p>
        <p className={classes.sessionInfoDesc} style={{ marginBottom: 0 }}>
          {startDate}
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          {' - '}
          {endDate}
          <span style={{ fontWeight: 'normal' }}>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {' | '}
            {intl.formatMessage(
              { defaultMessage: '{length} minutes' },
              { length },
            )}
          </span>
        </p>
      </div>
    );
  };

  const renderHelpTextPaid = () => (
    <p className={classes.radioDescription}>
      {helpTextShown &&
        // eslint-disable-next-line no-nested-ternary
        (isConsultation
          ? hasPayout
            ? intl.formatMessage({
                defaultMessage:
                  'The client will not be charged and you will be paid.',
              })
            : intl.formatMessage({
                defaultMessage:
                  'The client will not be charged and you will not be paid.',
              })
          : intl.formatMessage({
              defaultMessage:
                'The client will be charged and you will be paid.',
            }))}
    </p>
  );

  const renderHelpTextNotPaid = () => (
    <p className={classes.radioDescription}>
      {helpTextShown &&
        intl.formatMessage({
          defaultMessage:
            'The client will not be charged and you will not be paid.',
        })}
    </p>
  );

  const renderReasons = () => (
    <div className={classes.reasonBox}>
      <p className={classes.subTitle}>
        {intl.formatMessage({
          defaultMessage: 'Reason for Incompletion',
        })}
      </p>
      <RadioGroup name="reason" value={reason} onChange={handleChangeReason}>
        <div>
          <CustomFormControlLabel
            value="client_absence"
            control={<CustomRadio color="default" disableRipple />}
            label={intl.formatMessage({
              defaultMessage: 'Client Absent',
            })}
          />
          {renderHelpTextPaid()}
        </div>
        {isAdmin && appointmentStatus === 'client_cancellation' && (
          <div>
            <CustomFormControlLabel
              value="client_cancellation"
              control={<CustomRadio color="default" disableRipple />}
              label={intl.formatMessage({
                defaultMessage: 'Client Cancellation',
              })}
            />
            {renderHelpTextNotPaid()}
          </div>
        )}
        <div>
          <CustomFormControlLabel
            value="client_late_cancellation"
            control={<CustomRadio color="default" disableRipple />}
            label={intl.formatMessage({
              defaultMessage: 'Client Late Cancellation',
            })}
          />
          {renderHelpTextPaid()}
        </div>
        <div>
          <CustomFormControlLabel
            value="care_provider_cancellation"
            control={<CustomRadio color="default" disableRipple />}
            label={intl.formatMessage({
              defaultMessage: 'Care Provider Cancellation',
            })}
          />
          {renderHelpTextNotPaid()}
        </div>
        <div>
          <CustomFormControlLabel
            value="technical_difficulties"
            control={<CustomRadio color="default" disableRipple />}
            label={intl.formatMessage({
              defaultMessage: 'Care Provider Technical Difficulties',
            })}
          />
          {renderHelpTextNotPaid()}
        </div>
      </RadioGroup>
    </div>
  );

  const renderBody = () => {
    if (loading) {
      return (
        <div className={classes.radioBox}>
          <DataLoadingSpinner />
        </div>
      );
    }
    return (
      <div>
        <div>
          <p className={classes.title}>
            {intl.formatMessage({
              defaultMessage:
                'Please set the session status for the following appointment:',
            })}
          </p>
        </div>
        <div className={classes.sessionInfoBox}>{renderSessionInfo()}</div>
        <div className={classes.radioBox}>
          <p className={classes.subTitle}>
            {intl.formatMessage({
              defaultMessage: 'Set Appointment Status',
            })}
          </p>
          <FormControl component="fieldset">
            <RadioGroup
              name="session-status"
              value={sessionStatus}
              onChange={handleChangeSessionStatus}
            >
              <div>
                <CustomFormControlLabel
                  value="complete"
                  control={<CustomRadio color="default" disableRipple />}
                  label={intl.formatMessage({ defaultMessage: 'Complete' })}
                />
                {renderHelpTextPaid()}
              </div>
              <div>
                <CustomFormControlLabel
                  value="canceled"
                  control={<CustomRadio color="default" disableRipple />}
                  label={intl.formatMessage({ defaultMessage: 'Incomplete' })}
                />
              </div>
              {sessionStatus === 'canceled' && renderReasons()}
              <p className={classes.radioDescription} />
              {isAdmin && (
                <div>
                  <CustomFormControlLabel
                    value="pending"
                    control={<CustomRadio color="default" disableRipple />}
                    label={intl.formatMessage({ defaultMessage: 'Pending' })}
                  />
                  <p className={classes.radioDescription}>
                    {intl.formatMessage({
                      defaultMessage:
                        'The Care Provider can update a new session status after "Pending" is selected.',
                    })}
                  </p>
                </div>
              )}
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.errorMessageBox}>
          {showError && <p className={classes.errorMessage}>{errorMessage}</p>}
        </div>
        <div className={classes.flexCenter}>
          <Button
            colortype="green"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {intl.formatMessage({ defaultMessage: 'Complete Session' })}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        PaperProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <div className={classes.dialogBackground}>
          <GlobalTitle
            title={
              isForUpdate
                ? intl.formatMessage({
                    defaultMessage: 'Update Appointment Status',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Complete Session',
                  })
            }
          />
          {renderBody()}
        </div>
        <IconButton
          className={classes.closeButton}
          disableFocusRipple
          disableRipple
          size="small"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      {!isAdmin && props.showHstModal && (
        <TaxFormDialog open={taxDialog} setOpen={setTaxDialog} />
      )}
    </>
  );
}

SessionCompletion.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  appointmentId: PropTypes.number.isRequired,
  appointment: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  fetchAppointment: PropTypes.func.isRequired,
  cancelAppointment: PropTypes.func.isRequired,
  completeAppointment: PropTypes.func.isRequired,
  fetchAdminAppointment: PropTypes.func.isRequired,
  updateAdminAppointmentStatus: PropTypes.func.isRequired,
  cancelAdminAppointment: PropTypes.func.isRequired,
  openGlobalSnackbar: PropTypes.func.isRequired,
  handlePostChange: PropTypes.func,
  isAdmin: PropTypes.bool,
  isForUpdate: PropTypes.bool,
  showHstModal: PropTypes.bool,
  appointmentStatus: PropTypes.string,
  cancellation: PropTypes.object,
};

SessionCompletion.defaultProps = {
  handlePostChange: () => {},
  isAdmin: false,
  isForUpdate: false,
};

function mapStateToProps(state) {
  const { sessionStatus } = state;
  const { clinic_counsellor_profile: clinicCounsellorProfile } = state.user;
  const gst_hst_number = state.user?.provider_detail?.gst_hst_number;
  const taxExempt = state.user?.provider_detail?.custom_tax_yn;

  const showHstModal =
    COUNTRY === 'CA' &&
    !clinicCounsellorProfile &&
    !taxExempt &&
    (!gst_hst_number || gst_hst_number.length === 0);

  return {
    appointment: sessionStatus.appointment,
    loading: sessionStatus.appointment_loading,
    submitting: sessionStatus.update_loading,
    showHstModal,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetchAppointment,
    cancelAppointment,
    completeAppointment,
    fetchAdminAppointment,
    updateAdminAppointmentStatus,
    cancelAdminAppointment,
    openGlobalSnackbar,
  })(SessionCompletion),
);
